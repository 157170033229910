// eslint-disable-next-line no-redeclare,no-unused-vars
function addButtonBar(param) {
  var view = param.view;
  var object = param.object;
  var objectSub = param.objectSub;

  // individual buttons
  var buttons = getParamSettings(param, 'button');
  if (buttons)
    $.each(buttons, function (ident, button) {
      if (!button.noFilter && !param.table) return;

      // obsolete syntax
      if (isString(button)) {
        var value = button.split(',');
        button = {func: ident};
        [
          button.label,
          button.icon,
          button.noFilter,
          button.promptLabel,
          button.value,
        ] = value;
      }

      if (button.hidden && checkWhere({}, button.hidden)) return;

      var btn = $(
        '<button id="' +
          view.ident +
          '_' +
          ident +
          '" type="button" class="btn btn-light btn-fix"></button>'
      );
      if (button.icon)
        btn.append('<i class="icon fa-regular fa-' + button.icon + ' fa-fw">');
      else btn.append(button.label.slice(0, 2).toUpperCase());
      btn
        .appendTo(view.buttonsLeft)
        .addTooltip(button.label)
        .on(click, function () {
          if (button.link) window.open(button.link);
          else if (button.jsFunc) {
            if (button.jsFunc == 'copy') copyTableRow(button);
            else window[button.jsFunc](object, objectSub, button);
          } else {
            var send = {func: button.func};
            if (button.promptLabel)
              send.promptValue = prompt(button.promptLabel);
            if (button.key && button.value) send[button.key] = button.value;
            else if (button.value) send.value = button.value;
            if (button.param)
              $.each(button.param, function (key, val) {
                send[key] = val;
              });
            if (!button.noFilter) {
              var result = param.table.filtered(send);
              if (result === false) {
                showInfo(info.pickedEmpty, 'warning');
                return;
              }
            }
            ajax({
              object: object,
              objectSub: objectSub,
              view: view,
              post: true,
              send: send,
            });
          }
        });
    });

  // add new button
  var noAdd = getParamSettings(param, 'noAdd');
  if (isArray(noAdd)) noAdd = checkInArray(role, noAdd);
  if (!param.read && !param.noButtons && !noAdd)
    $(
      '<button id="' +
        view.ident +
        '_new" type="button" class="btn btn-light btn-fix" key="new"><i class="icon fa-regular fa-plus fa-fw"></i></button>'
    )
      .appendTo(view.buttonsLeft)
      .addTooltip(param.title + ' erstellen [Alt+N]')
      .on(click, function () {
        view.new();
        if (param.addNewCallback) view.afterClose = param.addNewCallback;
      });

  if (!param.picker && !param.read && !param.noButtons) {
    if (!noAdd) {
      if (param.table && param.table.selectRow)
        $(
          '<button id="' +
            view.ident +
            '_copy" type="button" class="btn btn-light"><i class="icon fa-regular fa-copy fa-fw"></i></button>'
        )
          .appendTo(view.buttonsLeft)
          .addTooltip(param.title + ' kopieren')
          .on(click, function () {
            copyTableRow();
          });

      // upload / import button
      if (
        ((!getParamSettings(param, 'noUpload') && !param.noUpload) ||
          getParamSettings(param, 'upload')) &&
        mobile != 'smartphone'
      ) {
        var action = endpoint + object;
        if (objectSub) action += '+' + objectSub;
        action += '?func=upload';
        if (debug) action += '&debug=' + debug;
        var form = $(
          '<form class="upload btn-cont" enctype="multipart/form-data" action="' +
            action +
            '" actionOrig="' +
            action +
            '" type="upload"></form>'
        ).appendTo(view.buttonsLeft);
        var upload = $(
          '<input id="' +
            view.ident +
            '_upload" class="upload" name="file[]" type="file" multiple="true">'
        )
          .appendTo(form)
          .on('change', function () {
            form.submit();
          });
        var btn = $(
          '<button type="button" class="btn btn-light btn-fix"><i class="icon fa-regular fa-file-import fa-fw"></i></button>'
        )
          .prependTo(form)
          .addTooltip(param.title + ' importieren')
          .on(click, function () {
            upload.trigger(click);
          });
        var pm = {
          dataType: 'json',
          target: 'iframe',
          type: 'POST',
          object: object,
          objectSub: objectSub,
          view: view,
          headers: {Window: windowId},
          xhrFields: {withCredentials: true},
        };
        pm.beforeSubmit = function () {
          loading(true, true);
        };
        pm.success = function (xhr) {
          loading(false, true);
          if (xhr.confirm) {
            var action = endpoint + object;
            if (objectSub) action += '+' + objectSub;
            action += '?func=upload';
            if (debug) action += '&debug=' + debug;
            var pm2 = {
              title: 'Achtung',
              buttons: {},
              content: xhr.confirm.question,
              closeLabel: info.title.cancel,
            };
            pm2.buttons[xhr.confirm.yes] = function () {
              action += '&' + xhr.confirm.id + '=yes';
              form.attr('action', action).submit();
              popup.close();
            };
            pm2.buttons[xhr.confirm.no] = function () {
              action += '&' + xhr.confirm.id + '=no';
              form.attr('action', action).submit();
              popup.close();
            };
            pm2.closeFunc = function () {
              upload.val('');
              form.attr('action', action);
              popup.close();
            };
            var popup = buildPopup(pm2);
            return;
          }
          ajaxResponse($.extend(true, {}, pm), xhr);
          upload.val('');
          form.attr('action', form.attr('actionOrig'));
          btn.removeClass('active').css('background-image', '');
        };
        pm.uploadProgress = function (evt) {
          var rest = Math.trunc((evt.loaded * 100) / evt.total);
          btn.css(
            'background-image',
            'linear-gradient(to right, rgb(' +
              design.lines +
              ') ' +
              rest +
              '%, rgb(' +
              design.background +
              ') 0)'
          );
        };
        form.ajaxForm(pm);
        contextMenu(
          btn,
          {download: 'Importvorlage herunterladen'},
          function () {
            ajax({
              object: object,
              objectSub: objectSub,
              view: view,
              send: {func: 'exportCsvPattern'},
            });
          }
        );
      }
    }

    var downloader = getParamSettings(param, 'downloader');
    if (downloader && param.table)
      $(downloader).each(function (i, key) {
        var set = {};
        if (strpos(key, '.') !== false)
          $.extend(true, set, getRefSettings(key, object));
        else $.extend(true, set, fields[object][key]);
        if (set.reference != 'document') return;
        set.field = 'pdf';
        set.label = 'Sammel-PDF ' + set.label;
        // if (set.file == 'preview') delete set.file;
        if (
          set.pdfPattern ||
          optionValue({
            settings: set,
            key: 'pdfPattern',
            objectSub: objectSub,
          })
        )
          set.noPreview = true;
        var createExit = function (pm) {
          var send = {func: 'downloader', key: key};
          var result = param.table.filtered(send);
          if (result === false) {
            showInfo(info.pickedEmpty, 'warning');
            return false;
          }
          pm.object = object;
          pm.objectSub = objectSub;
          Object.assign(pm.send, send);
        };
        buildField(
          view.buttonsLeft,
          {
            object: object,
            objectSub: objectSub,
            view: view,
            createExit: createExit,
            downloader: true,
          },
          key,
          set
        );
      });

    var btnMailer = getParamSettings(param, 'btnMailer');
    if (btnMailer && param.table) {
      $(
        '<button type="button" class="btn btn-light"><i class="icon fa-regular fa-envelope fa-fw"></i></button>'
      )
        .addTooltip('E-Mail an ' + param.title)
        .appendTo(view.buttonsLeft)
        .on(click, function () {
          var selected = param.table.selected();
          if (!selected.length) {
            showInfo(info.pickedEmpty, 'warning');
            return;
          }
          var pm = {
            top: view,
            settings: fields[object][btnMailer],
            object: object,
            objectSub: objectSub,
            key: btnMailer,
          };
          if (selected.length == 1) pm.row = data[object][selected[0]];
          else pm.massMailer = true;
          mailer(pm);
        });
    }

    if (param.table && param.table.selectRow)
      $(
        '<button class="btn btn-light" type="button"><i class="icon fa-regular fa-layer-group fa-fw"></i></button>'
      )
        .addTooltip(info.context.editMultiRows)
        .appendTo(view.buttonsLeft)
        .on(click, function () {
          param.table.massEdit();
        });
    if (
      param.table &&
      param.table.selectRow &&
      !getParamSettings(param, 'noRemove')
    )
      $(
        '<button type="button" class="btn btn-light btn-fix"><i class="icon fa-regular fa-trash-alt fa-fw"></i></button>'
      )
        .appendTo(view.buttonsLeft)
        .addTooltip(info.context.disableRows)
        .on(click, function () {
          param.table.remove('disable', 'löschen');
        });
  }

  // csv export
  if (param.table) {
    btn = $(
      '<button type="button" class="btn btn-light"><i class="icon fa-regular fa-file-export fa-fw"></i></button>'
    )
      .appendTo(view.buttonsLeft)
      .addTooltip(param.title + ' exportieren')
      .on(click, function () {
        var send = {func: 'excel'};
        if (param.table.selectRow) {
          var result = param.table.filtered(send);
          if (result === false) {
            showInfo(info.pickedEmpty, 'warning');
            return;
          }
        }

        var pm = {
          title: info.title.chooseExportRows,
          modal: false,
          top: view,
          fields: {},
          list: view.table.tableFields,
          alwaysSave: true,
          save: function (tableFields) {
            if (tableFields) {
              send.fields = tableFields;
              if (!view.viewSelected)
                localStorage.setItem(
                  param.table.ident + '_exportFields',
                  tableFields
                );
            }
            ajax({
              object: object,
              objectSub: objectSub,
              view: view,
              send: send,
              post: true,
            });
          },
        };
        $.each(fields[object], function (key, settings) {
          pm.fields[key] = {label: settings.label};
        });

        // add reference fields
        var exportRefFields = getParamSettings(param, 'exportRefFields');
        if (exportRefFields)
          $.each(exportRefFields, function (i, key) {
            var settings = fields[object][key];
            $.each(fields[settings.reference], function (refKey, refSettings) {
              pm.fields[key + '.' + refKey] = {
                label: settings.label + ' ' + refSettings.label,
              };
            });
          });

        // if (!view.viewSelected ) {
        //   var tableFieldsCache = localStorage.getItem(
        //     param.table.ident + '_exportFields'
        //   );
        //   if (tableFieldsCache) tableFields = tableFieldsCache;
        // }
        sorter(pm);
      });
    contextMenu(btn, {all: info.context.exportAllFields}, function () {
      var send = {func: 'excel', all: true};
      // FIXME table is not defined
      // eslint-disable-next-line no-undef
      var result = table.filtered(send);
      if (result === false) {
        showInfo(info.pickedEmpty, 'warning');
        return;
      }
      ajax({
        object: object,
        objectSub: objectSub,
        view: view,
        send: send,
        post: true,
      });
    });
  }

  // add to dashboard
  if (param.table && !param.picker && !param.dashboard) {
    $(
      '<button class="btn btn-light" type="button"><i class="icon fa-regular fa-bookmark fa-fw"></i></button>'
    )
      .appendTo(view.buttonsLeft)
      .addTooltip(info.tooltip.addResultDashboard)
      .on(click, function () {
        buildPromptPopup(
          [{label: info.title.cardName, value: param.title}],
          function (label) {
            loading(true, true);
            var box = object + rand();
            fields.start[box] = {
              object: object,
              fields: param.table.tableFields,
              label: label,
              objectSub: param.objectSub,
              where: param.table.where,
              sort: param.table.sort,
            };
            var boxes = getParamSettings({object: 'start'}, 'boxes');
            if (!boxes) boxes = [];
            ajax({
              post: true,
              object: 'setting',
              send: {
                object: 'start',
                key: box,
                value: fields.start[box],
              },
              background: true,
              callback: function (xhr) {
                if (xhr.box) boxes.push(xhr.box);
                else boxes.push(box);
                ajax({
                  post: true,
                  object: 'setting',
                  send: {
                    object: 'start',
                    key: 'boxes',
                    value: boxes,
                  },
                  background: true,
                  callback: function () {
                    ajax({
                      object: 'start',
                      send: {update: true},
                      callback: function (xhr) {
                        fillDashboard(xhr);
                      },
                    });
                    parameter.start[boxes + ucfirst(role)] = boxes;
                    dashboard.show();
                  },
                });
              },
            });
          }
        );
      });
  }

  var copyTableRow = function (button = {}) {
    var selected = param.table.selected();
    if (selected.length != 1) {
      showInfo(info.pickedOne);
      return;
    }
    param.table.deselectAll();
    var pm = {
      object: object,
      objectSub: objectSub,
      callback: function (xhr) {
        detail({
          object: object,
          objectSub: pm.objectSub,
          id: xhr.id,
          top: view,
        });
      },
    };
    if (button.objectSub) pm.objectSub = button.objectSub;
    copy(data[object][selected[0]].id, pm);
  };
}
